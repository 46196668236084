<template>
  <div class="dropdown-checkbox" :class="{'dropdown-checkbox-flow': flowStyle}" v-click-outside="clickOutsideHandlerHelp">
    <base-button-ghost
      :text="title"
      class="dropdown-button full-height"
      :class="{'open': active}"
      icon="arrow-move-down" 
      icon-active="arrow-move-up"
      :active-prop="active"
      @state-change="setActive"
      :disabled="isDisabled"
    />
    <div v-if="active && options.length" class="dropdown-options | flow" :class="{bordered: !flowStyle, mobile: mobile}" :data-dropdown-align="align">
        <label 
          v-for="o in options" 
          :key="o.name" 
          class="dropdown-option capitalize-first" 
          :for="o.name">

          <input  type="checkbox" :value="o.name" :id="o.name" :checked="o.selected" @change="updateChecked">

          {{o.name}}

        </label>
      
    </div>
  </div>
</template>

<script>
import button from '@mixins/button.js';
import { mapState } from 'vuex';

export default {
  name: 'DropdownCheckbox',
  mixins: [ button ],
  props: {
    title: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    flowStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    preventOutClose: {
      type: Boolean,
      required: false,
      default: false
    },
    align: {
      type: String,
      required: false,
      default: 'left',
      validator: (val) => ['left', 'right'].indexOf(val) >= 0
    }
  },
  computed: {
    ...mapState('interface', ['mobile']),
    isDisabled() {
      return !this.options.length;
    },
  },
  methods: {
    updateChecked(event) {
      let rtn = {
        key: event.target.value,
        value: event.target.checked,
        id: this.$el.dataset['id']
      }
      this.$emit('selection-changed', rtn)
    },
    clickOutsideHandlerHelp(event) {
      if (!this.preventOutClose) {
        this.clickOutsideHandler(event)
      }
    }
  }
}
</script>

<style lang="scss">
.dropdown-checkbox {

  .dropdown-button {
    text-transform: uppercase;
  }
  .dropdown-options {
    --flow-space: var(--s0);

    &:not(.mobile) {
      max-height: calc(100vh - var(--navbar-height)*4);
      overflow: auto;      
    }

    label.dropdown-option {
      cursor: pointer;
      display: block;
    }
  }

  &:not(.dropdown-checkbox-flow) {
    .dropdown-button {
      width: 11.44rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropdown-button.open {
      border-left: solid 0.0625rem var(--color-border);
      border-right: solid 0.0625rem var(--color-border);
      border-top: solid 0.0625rem var(--color-border);
    }
    .dropdown-button:hover:not(.open) {
      --color-border: var(--color-light);
      border-left: solid 0.0625rem var(--color-border);
      border-right: solid 0.0625rem var(--color-border);
    }

    .dropdown-options {
      width: fit-content;
      padding: var(--s2);
      background-color: var(--color-white);
      position: absolute;
      z-index: 2;

      &[data-dropdown-align="right"] {
        right: 0;
      }
    }    
  }

  &.dropdown-checkbox-flow {
    .dropdown-options {
      padding-top: var(--s0);
      padding-left: var(--s1);
    }
  }
}
</style>
