import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
        }
    },
    computed: {
        query() {
            return this.$route.query;
        }
    },
    watch: {
        query(n, o) {
            // console.log("query from ", o, "to", n);
            this.queryChangeHandler();
        }
    },
    methods: {
        ...mapActions('route', ['parseQuery']),
        updateQuery(obj) {
            this.$router.replace({ query: {...this.$route.query, ...obj} }).catch(()=>{});
        },
        queryChangeHandler() {
            this.parseQuery(this.query)
                .then(() => {
                    if (this.parseQueryCb) this.parseQueryCb();
                });
        }
    },
    mounted() {
        this.queryChangeHandler();
    }
}