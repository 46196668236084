export default {
    data() {
        return {
            currentOrderingSlug: "relevance"       
        }
    },
    computed: {
        currentOrdering() {
            return this.ordering.find(el => el.slug == this.currentOrderingSlug);
        },
        ordering() {
            return [
              {
                slug: "relevance",
                label: this.$t("ordering.relevance"),
                order: 0,
                query: 'relevance'
              },
              {
                slug: "alphaIncreasing",
                label: this.$t("ordering.alphaIncreasing"),
                order: 1,
                query: 'title'
              },
              {
                slug: "alphaDecreasing",
                label: this.$t("ordering.alphaDecreasing"),
                order: 2,
                query: '-title'
              },
              {
                slug: "dateIncreasing",
                label: this.$t("ordering.dateIncreasing"),
                order: 3,
                query: 'date'
              },
              {
                slug: "dateDecreasing",
                label: this.$t("ordering.dateDecreasing"),
                order: 4,
                query: '-date'
              }
            ]
        }
    },
    methods: {
        getOrderingOptionsBySlug(option, slugs) {
            let rtn = [];
            slugs.forEach(s => {
                let opt = this[option].find(el => el.slug == s);
                if (opt) rtn.push(opt)
            })
            return rtn.sort((a,b) => a.order < b.order);
        },
        setOrderingOptionBySlug(slug) {
            this.currentOrderingSlug = slug
        }
    }
}