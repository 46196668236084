<template>
  <div id="autocomplete" v-if="showAutocomplete" :class="{mobile:mobile}">
    <div v-for="(gr, i) in autocompleteAvailable" :key="gr.type" class="flow" :class="{'dgrid-body': mobile}">
      <p class="text--overline2">{{$tc(gr.label, 2)}}</p>
      <button v-for="item in gr.items" class="autocomplete-item stack-horizontal" @click="handleClick(item)">
        <div v-if="item.preview_image" class="img-container">
          <img :src="parseMediaUrl(item.preview_image)" @error="setPlaceholder">
        </div>
        <p class="line-clamp">{{item.title}}</p>
      </button>
      <div v-if="i < autocompleteAvailable.length - 1" class="separator"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import utils from '@js/utils.js';

export default {
  name: 'Autocomplete',
  props: {
    inputSelector: {
      type: String,
      required: true
    },
    searchString: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      typing: false
    }
  },
  computed: {
    ...mapState('interface', ['mobile']),
    ...mapState('search', ['autocomplete']),
    ...mapGetters('search', ['autocompleteAvailable']),
    showAutocomplete() {
      return this.typing && this.autocompleteAvailable.length;
    }
  },
  watch: {
    searchString(n, o) {
      if (n != o && n.length && document.activeElement == this.inputEl) {
        this.typing = true;
        this.getAutocomplete(n);
      } else if (n != o && !n.length) {
        this.typing = false;
        this.clearAutocomplete();
        this.$emit('reset-search')
      }
    }
  },
  methods: {
    ...mapActions('search', ['getAutocomplete']),
    ...mapMutations('search', ['clearAutocomplete']),
    handleClick(item) {
      // console.log("autocomplete item clicked", item)
      switch(item.class) {
        case 'EthnographicSource':
          this.$router.push({ name: 'sheet', params: {id: item.id.toString(), slug: item.slug}})
          break;
        case 'Institution':
          this.$emit('autocomplete-clicked', {query: 'institution', value: item.title})
          break;
        case 'Database':
          this.$emit('autocomplete-clicked', {query: 'database', value: item.title})
          break;
        case 'Topic':
          this.$emit('autocomplete-clicked', {query: 'topic', value: item.title})
          break;
        default:
          break;
      }
      this.typing = false;
    },
    parseMediaUrl(url) {
      return utils.parseMediaUrl(url);
    },
    setPlaceholder(e) {
      e.target.src = require('@assets/images/img_placeholder.png');
    }
  },
  mounted() {
    this.typing = false;
    this.inputEl = document.querySelector(this.inputSelector);

    this.inputEl.addEventListener('focus', () => {
      if (this.searchString.length) {
        this.typing = true;
        this.getAutocomplete(this.searchString);
      }
    })

    this.inputEl.addEventListener('blur', (e) => {
      if ((!e.relatedTarget || !e.relatedTarget.closest('#autocomplete')) && (!e.target || !e.target.closest('#autocomplete'))) this.typing = false;
    })
  },
  activated() {
    this.typing = false;
  }
}
</script>

<style lang="scss">
#autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  background-color: var(--color-thin);
  width: 100%;

  padding: var(--s3) var(--s4);

  > div {
    --flow-space: 0.75rem;
  }

  .separator {
    margin: var(--s2) 0;
    border-top: solid 1px var(--color-white);
  }
  // height: 200px;
  .autocomplete-item {
    --flow-space-horizontal: var(--s2);
    .img-container {
      width: 3rem;
      height: 3rem;
      border-radius: 0.1875rem;
      overflow: hidden;
      flex-shrink: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    p {
      --line-clamp-num: 1;
      text-align: left;
    }
  }

  &.mobile {
    padding: var(--s2) 0;

    .autocomplete-item {
      --flow-space-horizontal: var(--s1);
    }
  }
}
</style>