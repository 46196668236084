<template>
  <div id="explore" :class="{mobile}">

    <!-- <div id="test-loading-state" style="position: fixed;top:0;left:0;z-index: 1000;width: 50px; aspect-ratio:1;border-radius: 50%;" :style="testLoadingStateStyle"></div> -->

    <header class="explore-header">

      <div id="search-bar" class="full-width header stack-horizontal | bordered-bottom" data-border-color="light" data-align="center">     
        <base-icon name="search" />
        <input type="text" :placeholder="$t('homepage_cerca_home')" class="full-width | text--body1" v-model="searchString" @keyup.enter="search">
        <base-icon v-if="searchString ? searchString.length : false" name="delete" class="search-reset-icon pointer" @icon-clicked="resetSearchString" />
        <base-button 
          v-if="!mobile"
          :text="currentSearchTarget.label"
          class="pushed-right"
          type="primary" 
          icon="arrow-move-down" 
          icon-active="arrow-move-up" 
          dropdown
          :dropdownList="searchTargetOptions"
          @option-selected="changeSearchTargetHandler"
        />

        <div class="icon-container pointer" @click="mobileFilterStatus = true">
          <base-icon v-if="mobile" name="filter" />
        </div>

        <interface-autocomplete
          input-selector="#search-bar input"
          :search-string="searchString"
          @autocomplete-clicked="handleAutocompleteClicked"
          @reset-search="resetSearchString"
        />

      </div>

      <div id="filter-bar" v-if="!mobile" class="full-width header | stack-horizontal | bordered-bottom" data-border-color="light" data-justify="spaced">

        <interface-dropdown-checkbox 
          v-for="(f, i) in filters"
          :key="f.id"
          :title="$t(f.slug)"
          :data-id="f.id"
          :options="f.options"
          :align="i == filters.length-1 ? 'right' : 'left'"
          @selection-changed="setFilterHandler"
        />
      </div>

      <!-- desktop-style status bar -->
      <div v-if="!mobile" id="status-bar" class="full-width header stack-horizontal | bordered-bottom" data-align="center">
        <base-filter v-for="f in filtersHistory" :key="f.slug+f.filterId" :text="f.name" @removed="filterRemovedHandler(f)"/>
        <base-button-ghost v-if="filtersHistory.length" :text="$t('esplora_bottone_filtro_cancella')" icon="refresh" reversed @clicked="clearTags"/>
        <span class="pushed-right | text--body2">{{numResults+' '+$t('esplora_n_risultati')}}</span>
        <div class="stack-horizontal text--body2" data-align="center" style="--flow-space-horizontal: var(--s0); margin-left: var(--s3);">
          <span>{{$t('global_testo_ordina')}}:</span>
          <base-button 
            :text="currentOrdering.label" 
            type="tertiary" 
            icon="arrow-move-down" 
            icon-active="arrow-move-up" 
            dropdown
            :dropdownList="orderingOptions"
            @option-selected="changeOrderHandler"
          />          
        </div>
      </div>

      <!-- mobile-style status bar -->
      <div v-if="mobile" id="status-bar" class="full-width header | bordered-bottom" data-align="center">
        <p class="text--body2">{{numResults+' '+$t('esplora_n_risultati')}}</p>
        <p v-if="!filtersHistory.length" class="text--body2" style="color: var(--color-light)">{{$t('esplora_nessun_filtro')}}</p>
        <div v-if="filtersHistory.length" class="stack-horizontal">
          <base-filter v-for="f in filtersHistory" :key="f.slug+f.filterId" :text="f.name" @removed="filterRemovedHandler(f)"/>
        </div>        
        <div v-if="viewMode == 'list'" class="stack-horizontal text--body2" data-align="center" data-border-color="ultralight" style="padding-bottom: 0;">
          <span>{{$t('global_testo_ordina')}}:</span>
          <base-button 
            :text="currentOrdering.label"
            type="tertiary" 
            icon="arrow-move-down" 
            icon-active="arrow-move-up" 
            dropdown
            :dropdownList="orderingOptions"
            @option-selected="changeOrderHandler"
            fit-width
          />   
        </div>       
      </div>

      <!-- mobile-style filters drawer -->
      <div v-if="mobile" class="fixed-lateral-drawer | bg-white" :class="{opened: mobileFilterStatus}">
        <div class="header stack-horizontal | bg-black">
          <div class="icon-container | pushed-right cover | pointer" @click="mobileFilterStatus = false">
            <base-icon name="arrow-move-right" color-fill="white" />
          </div>
        </div>
        <div id="drawer-body" class="flow padded-lateral" >
          <div v-for="f in filters" :key="f.id" class="filter-group-container bordered-bottom" data-border-color="light">
            <interface-dropdown-checkbox 
              :title="$t(f.slug)"
              :data-id="f.id"
              :options="f.options"
              @selection-changed="setFilterHandler"
              flow-style
              :prevent-out-close="mobileFilterStatus"
            />
          </div>
          
        </div>
        <div id="drawer-buttons-container" class="stack-horizontal full-width bordered-top" data-justify="center">
          <div class="drawer-button-container | cover" data-align="start">
            <base-button-ghost :text="$t('esplora_bottone_filtro_cancella')" icon="refresh" reversed @clicked="clearTags"/>
          </div>
          <div class="drawer-button-container | cover">
            <base-button :text="$t('esplora_bottone_filtro_mostra_risultati')" type="secondary" fit-width @clicked="mobileFilterStatus = false"/>
          </div>          
        </div>
      </div>
    </header>

    <!-- desktop main view -->
    <main v-if="!mobile" id="explore-body-container" :class="{desktop:!mobile}">

      <!-- view mode control buttons -->
      <div id="view-type-buttons" class="flow" :data-view-mode="viewMode">
        <div class="map-button | cover" @click="setViewMode('map')" :class="{'selected': viewMode == 'map'}">
          <base-icon name="map-mode" :color-fill="viewMode == 'map' ? 'white' : 'black'"/>
        </div>
          <div class="map-button | cover" @click="setViewMode('mixed')" :class="{'selected': viewMode == 'mixed'}">
            <base-icon name="Hybrid-mode" :color-fill="viewMode == 'mixed' ? 'white' : 'black'"/>
        </div>
          <div class="map-button | cover" @click="setViewMode('list')" :class="{'selected': viewMode == 'list'}">
            <base-icon name="list-mode" :color-fill="viewMode == 'list' ? 'white' : 'black'"/>
        </div>
      </div>        

      <!-- explore body: list + map -->
      <div id="explore-body" class="full-width">
        <div id="explore-list" class="flow" style="--flow-space: 0" :style="listDrawerStyle">
          <div id="sheets-container" class="dgrid-body" :class="{'dgrid-minw-maxn dgrid-container': !mobile}" :data-view-mode="viewMode">
            <div class="sheet-container | bordered-bottom" data-border-color="ultralight"
              v-for="i in numCards" 
              :key="i">
              <!-- <div class="mockup-card"></div> -->
              <a :href="sheetsPage[i-1] ? `sheet/${sheetsPage[i-1].slug}` : ''" @click.prevent>
                <base-card-single-sheet 
                  :sheet="sheetsPage[i-1] ? sheetsPage[i-1] : {}"
                  type="list-vertical"
                  @clicked="sheetClickedHandler"
                  :data-sheet-id="sheetsPage[i-1] ? sheetsPage[i-1].id : ''"
                  :data-sheet-slug="sheetsPage[i-1] ? sheetsPage[i-1].slug : ''"
                />                
              </a>
            </div>            
          </div>
          <div class="cover navigation-container">
            <interface-navigation v-if="showNavigation" :num-pages="numPages" :page="currentPage" @page-changed="pageChangedHandler"/>
            <p v-else-if="!numResults">{{$t('no_results')}}</p>
          </div>
          

        </div>

        <div id="explore-map" :style="mapContainerStyle">
          <div id="map-loading-overlay" :class="{'map-loaded': !loadingByType('results') && mapLoaded}">
            <div id="mask-overlay" v-if="!mapLoaded" class="cover"><p>{{$t('explore_map_loading')}}</p></div>
            <div id="pulsating-overlay"></div>
          </div>
          <interface-map 
            v-if="mockupDoLoadMap"
            :markers="sheetsResults"
            @marker-clicked="markerClickedHandler"
            @cluster-clicked="clusterClickedHandler"
            ref="map"
            :controls="true"
            @popup-clicked="sheetClickedHandler"
            @loaded="handleMapLoaded"
          />    
        </div>        
      </div>
    </main>

    <!-- mobile main view -->
    <main v-else id="explore-body-container" :class="{ mobile }">

      <!-- explore body: list + map -->
      <div id="explore-body" class="full-width">

        <div id="explore-map" :style="mapContainerStyle">
          <div id="map-loading-overlay" :class="{'map-loaded': !loadingByType('results') && mapLoaded}">
            <div id="mask-overlay" v-if="!mapLoaded" class="cover"><p>{{$t('explore_map_loading')}}</p></div>
            <div id="pulsating-overlay"></div>
          </div>
          <interface-map
            v-if="mockupDoLoadMap"
            :markers="sheetsResults"
            :map-padding="mapPadding"
            @marker-clicked="markerClickedHandler"
            @cluster-clicked="clusterClickedHandler"
            @map-clicked="mapClickedHandler"
            @loaded="handleMapLoaded"
            ref="map"
            mobile
          />

          <div id="map-overlay" class="flow full-width" :data-overlay-type="`${clusterIsSelected ? 'cluster' : 'single'}`">
            
            <div class="padded-lateral">
              <div class="map-button mobile | cover pushed-right | selected" @click="setViewMode('list')">
                <base-icon name="list-mode" color-fill="white"/>
                <span>{{$t('esplora_bottone_mode_elenco')}}</span>
              </div>              
            </div>

            <div id="popup-container" class="popup-container-single | padded-lateral" v-if="sheetsSelected.length == 1" >
              <a :href="`sheet/${sheetSelected.slug}`" @click.prevent>
                <base-card-single-sheet 
                  :sheet="sheetSelected"
                  type="card"
                  @clicked="sheetClickedHandler"
                />                
              </a>
            </div>

            <div id="popup-container" class="stack-horizontal | popup-container-cluster" v-else-if="sheetsSelected.length > 1" > 
              <a v-for="s in sheetsByList('selected')" :href="`sheet/${s.slug}`" @click.prevent :key="s.id" class="map-popup-cluster">
                  <interface-card-single-text 
                    :title="s.title"
                    :subtitle-top="s.category"
                    :subtitle-bottom="s.institution"
                    title-size="h4"
                    :title-rows-limit="1"
                    ratio="2/1"
                    @clicked="sheetClickedHandler({ slug: s.slug })"
                  />
              </a>
            </div>

          </div>


        </div>

        <div id="explore-list" :style="listDrawerStyle">
          <div class="handle" @click="setViewMode('list')"><div></div></div>
          <div id="sheets-container" class="flow">
            <div class="sheet-container | bordered-bottom" data-border-color="ultralight"
              v-for="i in numCards" 
              :key="i">

              <!-- <div class="mockup-card"></div> -->

              <a :href="sheetsPage[i-1] ? `sheet/${sheetsPage[i-1].slug}` : ''" @click.prevent>
                <base-card-single-sheet 
                  :sheet="sheetsPage[i-1] ? sheetsPage[i-1] : {}"
                  type="list-vertical"
                  @clicked="sheetClickedHandler"
                  :data-sheet-id="sheetsPage[i-1] ? sheetsPage[i-1].id : ''"
                />                
              </a>

            </div>
            <div class="cover navigation-container">
              <interface-navigation v-if="showNavigation" :num-pages="numPages" :page="currentPage" @page-changed="pageChangedHandler"/>
              <p v-else-if="!numResults">{{$t('no_results')}}</p>
            </div>
            

          </div>
          <div id="map-button-container" class="full-width" :class="{ hidden: this.viewMode != 'list' }">
            <div class="map-button mobile | cover | selected" @click="setViewMode('map')">
              <base-icon name="map-mode" color-fill="white"/>
              <span>{{$t('esplora_bottone_mode_mappa')}}</span>
            </div>
          </div>
        </div>

      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import InterfaceMap from '@components/InterfaceMap.vue';
import InterfaceNavigation from '@components/InterfaceNavigation.vue';
import InterfaceAutocomplete from '@components/InterfaceAutocomplete.vue';
import InterfaceCardSingleText from '@components/InterfaceCardSingleText.vue';
import InterfaceDropdownCheckbox from '@components/InterfaceDropdownCheckbox.vue';
import filters from '@mixins/filters.js'
import ordering from '@mixins/ordering.js'
import query from '@mixins/query.js'
import utils from '@js/utils.js';
import langMixin from '@mixins/lang.js';

export default {
  name: 'explore',
  components: { InterfaceMap, InterfaceNavigation, InterfaceDropdownCheckbox, InterfaceCardSingleText, InterfaceAutocomplete },
  mixins: [ filters, query, ordering, langMixin ],
  data() {
    return {
      viewMode: 'mixed', //'list', 'map',
      currentSearchTargetSlug: 'all',
      searchString: undefined,
      init: false,
      mobileFilterStatus: false,
      mockupDoLoadMap: true,
      mockupLogFlow: false,
      mapBottomPadding: 0,
      ongoingTouch: undefined,
      touchOffset: 0,
      getPageOnlyFlag: false,
      mapLoaded: false
    }
  },
  computed: {
    
    ...mapState('interface', ['mobile']),
    ...mapState('sheets', ['numResults', 'pageSize', 'mapBbox']),
    ...mapGetters('sheets', ['sheetsByList', 'sheetSelected', 'loadingByType', 'numPages']),
    ...mapGetters('route', ['currentPage']),
    searchTargetOptions() {
      return [
        {
          slug: "all",
          label: this.$t("global_filtro_ricerca_tutti"),
          query: 'all'
        },
        {
          slug: "title",
          label: this.$t("global_filtro_ricerca_titolo"),
          query: 'title'
        },
        {
          slug: "description",
          label: this.$t("global_filtro_ricerca_descrizione"),
          query: 'description'
        },
        {
          slug: "metadata",
          label: this.$t("global_filtro_ricerca_metadati"),
          query: 'metadata'
        },
        // {
        //   slug: "institution",
        //   label: this.$t("global_filtro_ricerca_ente"),
        //   query: 'institution'
        // },
        // {
        //   slug: "topic",
        //   label: this.$t("global_filtro_ricerca_categoria"),
        //   query: 'topic'
        // }
      ]
    },
    testLoadingStateStyle() { // TODO MOCKUP
      return { backgroundColor: this.loadingByType('filters') && this.loadingByType('results') ? 'purple' :
              this.loadingByType('filters') && !this.loadingByType('results') ? 'red' :
              !this.loadingByType('filters') && this.loadingByType('results') ? 'blue' : 'green'};
    },
    searchIsEmpty() {
      return !this.filtersHistory.length && (!this.$route.query.search || !this.$route.query.search.length);
    },
    mapContainerStyle() {

      let appHeight = document.getElementById('app').offsetHeight; // TODO

      return {
        // height: `calc(${appHeight}px - var(--navbar-height)*${this.mobile ? 3 : 4})`,
        height: `calc(100vh - var(--navbar-height)*${this.mobile ? 3 : 4})`,
        flex: '1',
        backgroundColor: 'lightblue',
        top: `calc(var(--navbar-height)*${this.mobile ? 3 : 4})`
      }
    },
    listDrawerStyle() {
      let rtn = {};
      if (!this.mobile) {
        rtn = {
          width: `${this.viewMode == 'list' ? '100%' : '37rem'}`,
          marginLeft: `${this.viewMode == 'map' ? '-37rem' : '0%'}`
        }
      } else {
        rtn = {
          height: `${(this.viewMode == 'list' && !this.filtersHistory.length) ? `calc(var(--vh, 1vh) * 100 - ${document.querySelector('#navbar').clientHeight + document.querySelector('.explore-header').clientHeight + document.querySelector('.handle').clientHeight}px )` :
                      (this.viewMode == 'list' && this.filtersHistory.length) ? 'calc(var(--vh, 1vh) * 100 - var(--navbar-height)*4' :
                      this.viewMode == 'mixed' ? this.mixedViewHeight : '0px'}`
        }
      }
      return rtn;
    },
    sheetsResults() {
      return this.sheetsByList('results');
    },
    sheetsPage() {
      return this.sheetsByList('page');
    },
    sheetsSelected() {
      return this.sheetsByList('selected');
    },
    clusterIsSelected() {
      return this.sheetsByList('selected').length > 1;
    },
    orderingOptions() {
      return this.getOrderingOptionsBySlug("ordering", this.ordering.map(o => o.slug)) //that is: take all the ordering options
    },
    currentSearchTarget() {
      return this.searchTargetOptions.find(el => el.slug == this.currentSearchTargetSlug);
    },
    mapPadding() {
      return {
        top: 10,
        bottom: this.mobile ? 186 : 10,
        right: 10,
        left: 10
      }
    },
    mixedViewHeight() {
      let rtn = 192;
      if (this.touchOffset) {
        rtn += this.touchOffset;
      }
      return rtn/16 + 'rem';
    },
    numCards() {
      return this.loadingByType('page') ? this.pageSize : this.sheetsPage.length;
    },
    showNavigation() {
      return this.numResults && this.numResults > this.pageSize;
    }
  },
  watch: {
    mobile(n, o) {
      // console.log("mobile changed to ", n)
      this.setSheetSelectedByIds([]);
      this.mobileFilterStatus = false;
    },
    mapBbox(n, o) {
      if (!this.searchIsEmpty && this.numResults) {
        // this.mapFitBoundsFlag = false;
        this.mapFitBounds(n);
      }
    }
  },
  methods: {
    ...mapActions('sheets', ['getSheetsPage', 'getSheetsSelected']),
    ...mapActions('sheets', ['getFilters', 'getFiltersExlude', 'getSheetsShort']),
    langCb() {
      this.parseQueryCb();
    },
    markerClickedHandler(id) {
      this.setSheetSelectedByIds([]);
      this.setSheetSelectedByIds([id]);
      if (this.mobile) this.setViewMode('map');
    },
    clusterClickedHandler(ids) {
      // console.log("cluster clicked - inside the following ids: ", ids)
      this.setSheetSelectedByIds([]);
      this.setSheetSelectedByIds(ids);
      if (this.mobile) this.setViewMode('map');
    },
    setViewMode(mode) {
      this.viewMode = mode;
    },
    resizeMap() {
      if (this.viewMode != 'list') {
        this.$refs.map.resize();
      }
    },
    mapFitBounds(bounds) {
      this.$refs.map.fitBounds(bounds);
    },
    mapClickedHandler() {
      this.setSheetSelectedByIds([]);
    },
    sheetClickedHandler({slug}) {
      // go to sheet view
      console.log('sheetClickedHandler', slug)
      if (slug) {
        this.$router.push({name: 'sheet', params: {slug}});
        this.setSheetSelectedByIds([]);
      }
    },
    removeResizeListener() {
      // console.log("removeResizeListener")
      let listDrawer = document.getElementById("explore-list");
      if (listDrawer) listDrawer.removeEventListener('transitionend', this.resizeMap);
    },
    pageChangedHandler(page) {
      this.getPageOnlyFlag = true;
      // called when the page is changed by the navigation interface
      if (this.mockupLogFlow) console.log("pageChangedHandler - called when the PAGE is changed by the navigation interface")

      this.updateQuery({ page });
    },
    filtersChangedCb() {
      // called after the FILTERS have been changed
      if (this.mockupLogFlow) console.log("filtersChangedCb - called after the FILTERS have been changed")

      let rtn = {};
      Object.keys(this.appliedFilters).forEach(el => {
        let queryName = this.getFilterById(el).query;
        rtn[queryName] = this.appliedFilters[el];
      })
      this.updateQuery({...rtn, page: 1}); // if the filters have been changed, reset to page 1
    },
    parseQueryCb() {
      // called after the query string changes - TIME TO RETRIVE DATA FROM BACKEND!
      if (this.mockupLogFlow) console.log("parseQueryCb - called after the query string changes - TIME TO RETRIVE DATA FROM BACKEND!")

      if (this.$route.name == 'explore') {
        // search API call
        this.getData();
        
        // update the interface accordingly
        this.updateFiltersByQuery(this.$route.query); // here can be uneffective, also called after promise
        this.updateSearchStringByQuery(this.$route.query);
        this.updateSearchTargetByQuery(this.$route.query);
        this.updateSortingByQuery(this.$route.query);

        utils.scrollToTop();        
      }
    },
    filterRemovedHandler(historyRecord) {
      // called when a single FILTER has been removed by the X button
      if (this.mockupLogFlow) console.log("filterRemovedHandler - called when a single FILTER has been removed by the X button")

      let filter = this.getFilterById(historyRecord.filterId);
      let option = filter.options.find(el => el.slug == historyRecord.slug);
      this.setOptionSelected(option, false, historyRecord.filterId);
      if (this.filtersChangedCb) this.filtersChangedCb();
    },
    changeOrderHandler(option) {
      this.getPageOnlyFlag = true;
      // console.log("order changed", option)
      this.setOrderingOptionBySlug(option.slug);
      this.updateQuery({ sorting: option.query });
    },
    getData() {
      // TODO - here the API get for backend data
      // console.log("GET DATA FROM BACKEND")
      
      // close map overlay
      this.$refs.map.closePopupHandler();
      this.setSheetSelectedByIds([]);

      // TODO - maybe useless if API are correct
      // let unique = this.checkForUniqueFilter(this.query);
      
      if (this.getPageOnlyFlag) {
        this.getSheetsPage(this.query);
      } else {
        this.getFiltersExlude({
          queryObj: this.query,
          // exclude: unique
        })

        this.getSheetsPage(this.query);
        this.getSheetsShort(this.query);        
      }

      this.getPageOnlyFlag = false;
    },
    changeSearchTargetHandler(option) {
      this.setSearchTargetBySlug(option.slug);
      if (this.searchString && this.searchString.length) this.updateQuery({field: option.query, page: 1}); // if the filters have been changed, reset to page 1
    },
    setSearchTargetBySlug(slug) {
      this.currentSearchTargetSlug = slug
    },
    search(event) {
      // unfocus input
      if(event) {
      	event.preventDefault()
      	event.target.blur();
      }
      this.typing = false;

      this.updateQuery({search: this.searchString})
    },
    resetSearchString() {
      console.log('reset string')
      this.searchString = ''
      this.search()
    },
    updateSearchStringByQuery(queryObj) {
      if (queryObj.hasOwnProperty('search') && queryObj.search.length) {
        this.searchString = queryObj.search;
      }
    },
    updateSearchTargetByQuery(queryObj) {
      if (queryObj.hasOwnProperty('field')) {
        let slug = this.searchTargetOptions.find(el => el.query == queryObj.field).slug;
        this.setSearchTargetBySlug(slug)
      } else {
        this.setSearchTargetBySlug('all')
      }
    },
    updateSortingByQuery(queryObj) {
      if (queryObj.hasOwnProperty('sorting')) {
        let slug = this.ordering.find(el => el.query == queryObj.sorting).slug;
        this.setOrderingOptionBySlug(slug)
      } else {
        // this.setSearchTargetBySlug('relevance') // TODO - relevance is not implemented
      }
    },
    setSheetSelectedByIds(ids) {
      this.getSheetsSelected(ids);
    },
    checkForUniqueFilter(obj) {
      let count=[];
      this.filters.forEach(filter => {
        let q = filter.query;
        if ((obj[q] && (!Array.isArray(obj[q]) || obj[q].length))) {
          count.push(filter.resKey ? filter.resKey : filter.query);
        }
      })
      if (count.length == 1) {
        return count[0]
      }
    },
    handleTouchDrag(event, clickedCard) {
      
      if (this.ongoingTouch) { // DRAG EVENT
      } else { //TAP EVENT
        if (clickedCard) {
          let id = clickedCard.dataset.sheetId;
          // console.log('clickedCard', id,clickedCard.dataset, clickedCard)
          this.sheetClickedHandler(id);
        }
      }
      
    },
    addTouchEvents() {
      let list = document.getElementById('explore-list')

      this.exploreListTransition = list.style.transition;
      list.addEventListener('touchstart', (e) => {

        if (this.mobile && this.viewMode == 'mixed') {
          e.preventDefault();
          let target = e.target;
          let clickedCard = target.closest('.card.type-list-vertical');
          setTimeout(()=>{
            this.handleTouchDrag(e, clickedCard)
          },100)
          const touches = e.changedTouches;
          this.ongoingTouch = touches[0];  
        }
      })

      list.addEventListener('touchmove', (e) => {
        if (this.viewMode == 'mixed') {
          const touches = e.changedTouches;
          let diff = this.ongoingTouch.pageY - touches[0].pageY
          this.touchOffset = Math.max(0, diff);
          list.style.transition = 'unset'
          if (diff >= 200) {
            list.style.transition = this.exploreListTransition;
            this.setViewMode('list')
          }         
        }
      })

      list.addEventListener('touchend', (e) => {
        if (this.viewMode == 'mixed') {
          if (this.touchOffset < 200) {
            list.style.transition = this.exploreListTransition;
            this.touchOffset = 0;
            this.ongoingTouch = undefined;
          }
        }
      })
    },
    handleAutocompleteClicked({query, value}) {
      let args = {};
      args[query] = value;
      this.updateQuery(args);
    },
    handleMapLoaded() {
      this.mapLoaded = true;
    }
  },
  mounted() {
    let listDrawer = document.getElementById("explore-list");
    listDrawer.addEventListener('transitionend', this.resizeMap);

    this.addTouchEvents();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    let heightListHeightFixCb = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.removeEventListener('resize', heightListHeightFixCb)
    window.addEventListener('resize', heightListHeightFixCb)

  },
  activated() {
    if (!this.$route.query.search) this.searchString = undefined;
    if (this.$refs.map) this.resizeMap();
  },
  beforeDestroy() {
    this.removeResizeListener();
  }
}
</script>

<style lang="scss">
  @import '@css/commons/map.scss';
  
  #explore {
    --transition-duration: 0.5s;
    --transition-type: ease-out;
    --basic-desktop-element-width: 100%;
    --header-bar-padding: var(--s0) var(--s3);

    &.mobile {
      --header-bar-padding: var(--s0) var(--s1);

      #status-bar {
        flex-wrap: nowrap;
        > div.stack-horizontal {
          padding: var(--s0) 0;
        }
        > div.stack-horizontal:nth-child(2) {
          overflow-x: scroll;          
        }

        button.text--button {
          font-size: calc(0.8125 * var(--base-font-size));
          line-height: 1.55;
        }
      }
    }

    #search-bar {
      background-color: var(--color-primary-10);
      padding: var(--header-bar-padding);
      position: relative;
    }

    #search-bar input::placeholder {
    	color: var(--color-black-text);
    }

    #filter-bar {
      --color-border: var(--color-black-text);
      overflow: hidden;
    }

    .fixed-lateral-drawer {
      padding-top: var(--navbar-height);
      .header {
        height: var(--navbar-height);
      }
      #drawer-buttons-container {
        padding: var(--s1) var(--page-padding);
        position: fixed;
        background-color: white;
        bottom: 0;
        gap: 0;
        > div {
          width: 50%;
        }
      }
      #drawer-body {
        height: 100%;
        padding-top: var(--s3);
        padding-bottom: var(--s5);
        overflow-y: scroll;
        --flow-space: var(--s2);

        .filter-group-container {
          padding-bottom: var(--s0);
        }
      }
    }

    header {
      position: sticky;
      top: var(--navbar-height);
      background-color: var(--color-white);
      z-index: 80;
    }

    #status-bar {
      padding: var(--header-bar-padding);
      flex-wrap: wrap;
      white-space: nowrap;
    }

    .navigation {
      margin-bottom: var(--s2);
    }

    #explore-body-container {

      .navigation-container > p {
        text-transform: capitalize;
      }

      #map-loading-overlay {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;

        #mask-overlay,
        #pulsating-overlay {
          z-index: 1;
          height: 100%;
          width: 100%;
          background-color: var(--color-thin);
        }

        #pulsating-overlay {
          position: absolute;
          top:0;
          left: 0;
          opacity: 0.8;
          transition: opacity var(--transition-duration) var(--transition-type);
          animation-name: pulse;
          animation-iteration-count: infinite;
          animation-duration: 0.7s;
          animation-direction: alternate;
          animation-timing-function: ease-in;          
        }

        #mask-overlay {
          position: relative;
        }

        &.map-loaded {
          #pulsating-overlay {
            opacity: 0;
            animation-name: none;
            pointer-events: none;            
          }
        }
      }

      &.desktop {
        #explore-body {
          display: flex;
        }
        #explore-list {
          
          min-width: 22rem;
          transition: all var(--transition-duration) var(--transition-type);

          #sheets-container {

            padding: var(--s2) 0;
            --dgrid-gap: var(--s2);
            --dgrid-min-width: 25rem;
            --dgrid-maxn: 3;

            &[data-view-mode="mixed"] {
              margin: unset;
              padding: var(--s2);
            }
          }
          
          .sheet-container {
            padding-bottom: var(--s3);
          }
        }
        #explore-map {
          position: sticky;
          top: 0;
        }
        #view-type-buttons {
          position: fixed;
          top: calc(var(--navbar-height)*4 + var(--s1) + var(--s2) + var(--map-button-size)*2);
          width: calc(var(--map-button-size) + var(--s1));
          right: 0;
          z-index: 1;
          --flow-space: var(--s0);
          transition: top var(--transition-duration) var(--transition-type);

          &[data-view-mode="list"] {
            top: calc(var(--navbar-height)*4 + var(--s1))
          }
        }

        .navigation-container {
          padding-bottom: var(--s3);
        }
      }

      &.mobile {
        #map-overlay {
          --flow-space: 0rem;
          position: fixed;
          bottom: 0;
          // padding: 0px 0px 3rem 0px;

          &[data-overlay-type="single"] {
            --flow-space: var(--s1);
            padding: 0px 0px 3rem 0px;
          }


          #popup-container {
            
            &.popup-container-single {
              display: flex;
              align-items: flex-start;
              justify-content: center;       
            }

            &.popup-container-cluster {
              padding-left: var(--page-padding);
              overflow-x: scroll;
              height: fit-content;
              --flow-space-horizontal: 0.75rem;
              padding-bottom: 3rem;
              padding-top: 1rem;

              > .map-popup-cluster {
                max-width: 20rem;
                min-width: 15rem;
                padding: var(--s1);
              }
            }

            .card.type-card {
              width: 100%;
              max-width: 33rem;
              min-width: 20.93rem;
            }
          }        
        }
        #explore-list {
          background-color: var(--color-white);
          position: fixed;
          bottom: 0;
          height: 100%;
          width: 100%;
          // min-height: -webkit-fill-available;
          transition: height var(--transition-duration) var(--transition-type);
          overflow: hidden;
          z-index: 2;
          --flow-space: var(--s1);

          #sheets-container {
            // touch-action:pan-down;
            overscroll-behavior: contain;
            height: 100%;
            overflow: auto;
            padding: 0 1.25rem 0 1.25rem;
          }

          .sheet-container {
            max-width: var(--card-width-vertical-list-desktop);
            padding-bottom: var(--s1);
            margin-left: auto;
            margin-right: auto;
          }

          #map-button-container {
            position: absolute;
            bottom: var(--s1);
            display: flex;
            justify-content: center;
            opacity: 1;
            transition: opacity var(--transition-duration) var(--transition-type);

            &.hidden {
              opacity: 0;
            }
          }
        }

        .navigation-container {
          padding-bottom: var(--s5);
        }
      }
    }

    .card.type-list-vertical .cover-img {
      max-width: unset;
    }
  }

  .mockup-card {
    aspect-ratio: 2/1;
    width: 100%;
    background-color: green;
    border-radius: 20px;
  }
  .handle {
    width: 100%;
    height: var(--s3);
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      width: var(--s3);
      height: 0.1875rem;
      border-radius: 2px;
      background-color: var(--color-gray-medium);
    }
  }

  .search-reset-icon {
  	margin-right: var(--s1);
  }

  @keyframes pulse {
    from {opacity: 0.6;}
    to {opacity: 0.8;}
  }
</style>
