import validation from '@js/validations.js';
import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            filters: [
                {
                    id: 'databases',
                    slug: 'esplora_filtro_esplora_database',
                    query: 'database',
                    resKey: 'source_db', //'resKey' to be defined just if different from 'query'
                    options: [] 
                },
                {
                    id: 'categories',
                    slug: 'esplora_filtro_esplora_categorie',
                    query: 'topic',
                    resKey: 'category',
                    options: []    
                },
                {
                    id: 'regions',
                    slug: 'esplora_filtro_esplora_regioni',
                    query: 'region',
                    options: []
                },
                {
                    id: 'institutions',
                    slug: 'esplora_filtro_esplora_enti',
                    query: 'institution',
                    options: [] 
                },
                {
                    id: 'datatype',
                    slug: 'esplora_filtro_esplora_data_type',
                    query: 'data_type',
                    options: []    
                },
                // {
                //     id: 'rights',
                //     slug: 'esplora_filtro_esplora_diritti',
                //     query: 'rights',
                //     options: []  
                // }
            ],
            filtersHistory: [],
            activatedFlag: false   
        }
    },
    computed: {
        ...mapState('sheets', ['filterOptions']),
        ...mapGetters('sheets', ['filterOptionsByKey']),
        appliedFilters() {  // WARNING QUESTO CAUSA LA SCOMPARSA DI FILTRI INVOLONTARIA (DB+CATEGORIA-CATEGORIA)
            let rtn = {}
            this.filters.forEach(el => {
                rtn[el.id] = el.options.filter(el => el.selected).map(el => el.slug ? el.slug : el.name); //if no query slug, use name
            })
            return rtn;
        }
    },
    watch: {
        filterOptions: {
            handler(n, o) {
                // console.log("watch filterOptions")
                this.updateFilterOptions();
            },
            deep: true
        }
    },
    mounted() {
        this.updateFilterOptions();
    },
    deactivated() {
        this.activatedFlag = false;
    },
    methods: {
        ...mapMutations('sheets', ['setLatestFilterApplied']),
        getFilterById(id) {
            return this.filters.find(el => el.id == id);
        },
        getFilterByQuery(query) {
            return this.filters.find(el => el.query == query);
        },
        setFilterHandler(checkedObj) {
            // called when a FILTER is modified by the dropdown
            if (this.mockupLogFlow) console.log("setFilterHandler - called when a FILTER is modified by the dropdown")

            let opt = this.getFilterById(checkedObj.id).options.find(opt => opt.name == checkedObj.key);
            this.setOptionSelected(opt, checkedObj.value, checkedObj.id);
            if (this.filtersChangedCb) this.filtersChangedCb();
        },
        updateFiltersByQuery(queryObj) {
            if (this.$route.name == 'explore') {
                let queryKeys = Object.keys(queryObj);
                if (!this.activatedFlag) {
                    this.activatedFlag = true;
                    let toSplice = [];
                    this.filtersHistory.forEach(f => {
                        if (!queryKeys.includes(f.filterId)) {
                            let option = this.filters.find(el => el.id == f.filterId).options.find(el => el.slug == f.slug);
                            toSplice.push({
                                option: option,
                                id: f.filterId
                            })
                        }
                    });
                    toSplice.forEach(i => {
                        this.setOptionSelected(i.option, false, i.id); 
                    })
                }

                // called when the query string has been changed, update the interface accordingly
                if (this.mockupLogFlow) console.log("updateFiltersByQuery - called when the query string has been changed, update the interface accordingly")
                queryKeys.forEach(queryKey => {
                    let filter = this.getFilterByQuery(queryKey);
                    if (filter) {
                        filter.options.forEach(option => {
                            let isArray = Array.isArray(queryObj[queryKey]);
                            let matchString = option.slug ? option.slug : option.name.toLowerCase();
                            let condition = isArray ? queryObj[queryKey].includes(matchString) : matchString == queryObj[queryKey].toLowerCase();
                            let isSelected = !!option.name.length && condition; //if no query slug, use name 
                            this.setOptionSelected(option, isSelected, filter.id); 
                        })
                    }
                })
                // if (this.filtersChangedCb) this.filtersChangedCb();                
            }
        },
        setOptionSelected(option, selected, filterId) {
            // set a single FILTER option
            if (this.mockupLogFlow) console.log("setOptionSelected - set a single FILTER option")
            if (option) {
                this.$set(option, 'selected', selected);
                // update history
                let filterHistoryRecord;
                let index = this.filtersHistory.findIndex(el => filterId == el.filterId && option.slug == el.slug);
                if (selected && index < 0) {
                    filterHistoryRecord = {
                        name: option.name,
                        slug: option.slug,
                        filterId: filterId
                    }
                    this.filtersHistory.push(filterHistoryRecord);
                    let latestFilterApplied = this.filters.find(el => el.id == filterId)
                    this.setLatestFilterApplied(latestFilterApplied.resKey ? latestFilterApplied.resKey : latestFilterApplied.query)
                } else if (!selected && index >= 0) {
                    this.filtersHistory.splice(index, 1);
                    this.setLatestFilterApplied()
                }
            }
        },
        clearTags() {
            this.filters.forEach(filter => {
                filter.options.forEach(option => {
                    this.$set(option, 'selected', false);
                })
            })
            this.filtersHistory.splice(0, this.filtersHistory.length);
            if (this.filtersChangedCb) this.filtersChangedCb();
        },
        updateFilterOptions() {
            try {
                this.filters.forEach(f => {
                    let options = [];
                    this.filterOptionsByKey(f.resKey ? f.resKey : f.query).forEach(option => {
                        let selected = this.filtersHistory.filter(e=>e.filterId==f.id).findIndex(e=>e.slug==option) >= 0;

                        //EXEPTION FOR "VALLE D'AOSTA": MySQL doesn't like apostrophe
                        options.push({
                            name: option,
                            slug: option.toLowerCase() === "valle d'aosta" ? 'aosta' : option.toLowerCase(),
                            selected
                        })
                    })
                    options = options.sort(this.optionsSortingFunction);
                    if (options.length) this.$set(f, 'options', options)
                });
            } catch {
                console.error("updateFilterOptions failed")
            }
            this.updateFiltersByQuery(this.$route.query);
        },
        optionsSortingFunction(a, b) {
            return a.name.localeCompare(b.name);
        }
    }
}