<template>
  <div class="navigation | stack-horizontal noselect relative" data-align="center" :style="`--navigation-num-buttons: ${numButtonsVisible};`">
    <base-icon name="arrow-move-left" @icon-clicked="goToPage(currentPage - 1)" :color-fill="currentPage == 1 ? 'gray-light' : 'black'"/>
    <template v-for="i in numButtonsVisible" >
      <div 
        v-if="i == 1"
        :class="{current: currentPage == i}"
        class="navigation-button cover | text--button"
        :key="i"
        @click="goToPage(1)">
        1 
      </div>
      <div 
        v-else-if="i == numButtons"
        :class="{current: currentPage == numPages}"
        class="navigation-button cover | text--button"
        :key="i"
        @click="goToPage(numPages)">
        {{numPages}}
      </div>
      <div
        v-else-if="(i == 2 && isOverflowingLeft) || (i == numButtons - 1 && isOverflowingRight)"
        class="navigation-button navigation-ellipses" 
        :key="i">
        ...
      </div>
      <div 
        :class="{current: currentPage == i+offset}" 
        class="navigation-button cover | text--button"
        v-else 
        :key="i" 
        @click="goToPage(i+offset)">
        {{i+offset}}
      </div>
    </template>
    <base-icon name="arrow-move-right" @icon-clicked="goToPage(currentPage + 1)" :color-fill="currentPage == numPages ? 'gray-light' : 'black'"/>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      currentPage: this.page
    }
  },
  props: {
    numButtons: {
      type: Number,
      required: false,
      default: 5,
      validator: (v) => v % 2 && v > 3 // must be odd, and greater than 3
    },
    numPages: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: false,
      default: 1
    }
  },
  watch: {
    page(n) {
      this.currentPage = n;
    }
  }, 
  computed: {
    slotsAvailable() {
      return Math.floor(this.numButtons / 2);
    },
    offsetLimitUpper() {
      return Math.max(0, this.numPages - this.numButtons);
    },
    isOverflowing() {
      return this.numPages > this.numButtons;
    },
    isOverflowingRight() {
      return this.isOverflowing && this.currentPage < this.numPages - this.slotsAvailable;
    },
    isOverflowingLeft() {
      return this.isOverflowing && this.currentPage > this.slotsAvailable + 1;
    },
    offset() {
      return Math.min(Math.max(0, this.currentPage - (this.slotsAvailable + 1)), this.offsetLimitUpper);
    },
    numButtonsVisible() {
      return Math.min(this.numButtons, this.numPages);
    }
  },
  methods: {
    goToPage(index) {
      if (index > 0 && index <= this.numPages) {
        this.currentPage = index;
        this.$emit('page-changed', this.currentPage);
      }
    }
  }
}
</script>

<style lang="scss">
.navigation {

  --navigation-num-buttons: 5;
  --navigation-button-size: var(--s3);
  --flow-space-horizontal: var(--s1);

  .icon {
    flex-shrink: 0;
    cursor: pointer;
  }

  .navigation-button {
    cursor: pointer;
    width: fit-content;
    min-width: var(--navigation-button-size);
    height: var(--navigation-button-size);
    border-radius: 2rem;
    background-color: var(--color-white);
    font-size: 1rem;
    border: 0.125rem solid var(--color-light);
    text-align: center;
    padding: 0 0.5rem;

    &:hover {
      background-color: var(--color-ultralight);
    }

    &.navigation-ellipses {
      border: 0;
      background-color: transparent;
    }
  }
  .navigation-button.current {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: white;

    &:hover {
      background-color: var(--color-primary-125);
      border-color: var(--color-primary-125);
    }
  }
}
</style>
